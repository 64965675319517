$colorTags: 'a.chakra-link', 'a.chakra-link svg path ', a, b, blockquote, button, canvas, caption, div, dl, dt, em, embed, fieldset, figcaption, figure, font,
  footer, form, header, i, img, input, li, legend, menu, ol, pre, section, select, small, span, sup, table, td, th, tr, tt, u, ul, 'a span', strong;
$borderTags: 'span.tag', button, input, textarea, select, table, td, th, tr, tt;
$fontResizeTags: p, li, label, input, select, textarea, legend, code, pre, dd, dt, span, blockquote;
$fontResizeHeadings: h1, h2, h3, h4, h5, h6, h1 span, h2 span, h3 span, h4 span, h5 span, h6 span;
$fontSizes: 'font-120' 120 159 159.6, 'font-130' 130 172 172.9, 'font-140' 140 186 186.2, 'font-150' 150 199 199.5, 'font-160' 160 212 212.8,
  'font-170' 170 226 226.1, 'font-180' 180 239 239.4, 'font-190' 190 252 252.7;

body {
  //FONT RESIZE
  @each $name, $size, $headingName, $headingSize in $fontSizes {
    &.resize-#{$name} {
      %font#{$size} {
        font-size: $size * 1% !important;
      }
      @each $i in $fontResizeTags {
        #{$i} {
          @extend %font#{$size};
        }
      }

      %font#{$headingName} {
        font-size: $headingSize * 1% !important;
      }
      @each $i in $fontResizeHeadings {
        #{$i} {
          @extend %font#{$headingName};
        }
      }
    }
  }

  // UNDERLINE LINKS
  &.links-underline a {
    &,
    * {
      text-decoration: underline !important;
    }
  }

  // READABLE FONT
  &.readable-font {
    &,
    *:not(i):not(span) {
      font-family: Verdana, Arial, Helvetica, sans-serif !important;
    }
  }

  // GRAYSCALE
  &.grayscale {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: gray;
  }

  // NEGATIVE CONTRAST
  &.negative-contrast {
    &,
    header,
    header *,
    footer,
    footer *,
    footer ul li span,
    main,
    main section:not(.hero-wrapper):not(.card-media),
    main article div *,
    main article span *,
    main article p,
    .card-media .tag,
    .image-gallery-index,
    .chakra-modal__content,
    table td {
      background: #000 !important;
    }
    .slick-dots li button:before,
    & * {
      color: #ffffff !important;
    }

    main .card-media__inner,
    main section .hero-inner,
    main .hero .hero-inner {
      background-image: unset !important;
      background-color: rgba(0, 0, 0, 0.4) !important;
    }

    .tag {
      box-shadow: unset;
    }

    .card-media .tag svg path {
      fill: #fff !important;
    }

    @each $color in $colorTags {
      #{$color},
      *:not(.ac-toolbar) #{$color} {
        color: yellow !important;
      }
    }

    @each $border in $borderTags {
      #{$border},
      *:not(.ac-toolbar) #{$border} {
        border: 1px solid #ffffff !important;
      }
    }

    button.ac-tool__trigger {
      background: #000 !important;
      color: yellow !important;
      border: 1px solid #fff !important;
    }

    div#gmap,
    div#gmap * {
      background: initial !important;
    }
  }

  // LIGHT BACKGROUND
  &.light-background {
    &,
    header,
    header *,
    footer,
    footer *,
    footer ul li span,
    main,
    main section:not(.hero-wrapper):not(.card-media),
    main article div *,
    main article span *,
    main article p,
    .card-media .tag,
    .image-gallery-index,
    .chakra-modal__content,
    table td,
    .image-gallery-icon {
      background: #fff !important;
    }
    .slick-dots li button:before,
    & * {
      color: #000 !important;
    }

    main .card-media__inner,
    main section .hero-inner,
    main .hero .hero-inner {
      background-image: unset !important;
      background-color: rgba(255, 255, 255, 0.1) !important;
    }

    ul[role='list'] {
      border-color: #000 !important;
    }

    button:hover {
      background-color: unset !important;
    }

    .tag {
      box-shadow: unset;
    }

    .card-media .tag svg path {
      fill: #fff !important;
    }

    @each $color in $colorTags {
      #{$color} {
        // *:not(.ac-toolbar) #{$color} {
        color: #000 !important;
      }
    }

    @each $border in $borderTags {
      #{$border} {
        border: 1px solid #ffffff !important;
      }
    }

    button.ac-tool__trigger {
      background: #fff !important;
      color: #000 !important;
      border: 1px solid #000 !important;
    }

    div#gmap,
    div#gmap * {
      background: initial !important;
    }
  }

  // HIGH CONTRAST
  &.high-contrast {
    * {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    a,
    abbr,
    acronym,
    address,
    article,
    aside,
    b,
    basefont,
    bdi,
    big,
    blink,
    blockquote,
    body,
    button,
    canvas,
    caption,
    center,
    cite,
    code,
    col,
    colgroup,
    command,
    dd,
    del,
    details,
    dfn,
    dir,
    div:not(.hero):not(.card-media):not(.chakra-modal__overlay),
    dl,
    dt,
    em,
    embed,
    fieldset,
    figcaption,
    figure,
    font,
    footer,
    form,
    h1,
    h1 a,
    h1 a b,
    h1 abbr,
    h1 b,
    h1 center,
    h1 em,
    h1 i,
    h1 span,
    h1 strong,
    h2,
    h2 a,
    h2 a b,
    h2 abbr,
    h2 b,
    h2 center,
    h2 em,
    h2 i,
    h2 span,
    h2 strong,
    h3,
    h3 a,
    h3 a b,
    h3 abbr,
    h3 b,
    h3 center,
    h3 em,
    h3 i,
    h3 span,
    h3 strong,
    h4,
    h4 a,
    h4 a b,
    h4 abbr,
    h4 b,
    h4 center,
    h4 em,
    h4 i,
    h4 span,
    h4 strong,
    h5,
    h5 a,
    h5 a b,
    h5 abbr,
    h5 b,
    h5 center,
    h5 em,
    h5 i,
    h5 span,
    h5 strong,
    h6,
    h6 a,
    h6 a b,
    h6 abbr,
    h6 b,
    h6 center,
    h6 em,
    h6 i,
    h6 span,
    h6 strong,
    header,
    hgroup,
    html,
    i,
    iframe,
    img,
    input,
    ins,
    kbd,
    label,
    legend,
    li,
    listing,
    main,
    mark,
    marquee,
    menu,
    meter,
    multicol,
    nav,
    nobr,
    object,
    ol,
    option,
    output,
    p,
    plaintext,
    pre,
    progress,
    q,
    rb,
    rp,
    rt,
    ruby,
    s,
    samp,
    section,
    select,
    small,
    span,
    strike,
    strong,
    sub,
    summary,
    sup,
    svg,
    table,
    tbody,
    td,
    text,
    textarea,
    th,
    thead,
    time,
    tr,
    tt,
    u,
    ul,
    var,
    video,
    xmp {
      -moz-appearance: none !important;
      -moz-user-select: text !important;
      -webkit-user-select: text !important;
      background-image: none !important;
      text-shadow: none !important;
      user-select: text !important;
    }
    a,
    abbr,
    acronym,
    address,
    article,
    aside,
    b,
    basefont,
    bdi,
    big,
    blink,
    blockquote,
    body,
    canvas,
    caption,
    center,
    cite,
    code,
    col,
    colgroup,
    command,
    dd,
    del,
    details,
    dfn,
    dir,
    div:not(.hero):not(.hero-inner):not(.card-media):not(.card-media__inner):not(.chakra-modal__overlay):not(.chakra-modal__content-container),
    dl,
    dt,
    em,
    embed,
    fieldset,
    figcaption,
    figure,
    font,
    footer,
    form,
    h1,
    h1 a,
    h1 a b,
    h1 abbr,
    h1 b,
    h1 center,
    h1 em,
    h1 i,
    h1 span,
    h1 strong,
    h2,
    h2 a,
    h2 a b,
    h2 abbr,
    h2 b,
    h2 center,
    h2 em,
    h2 i,
    h2 span,
    h2 strong,
    h3,
    h3 a,
    h3 a b,
    h3 abbr,
    h3 b,
    h3 center,
    h3 em,
    h3 i,
    h3 span,
    h3 strong,
    h4,
    h4 a,
    h4 a b,
    h4 abbr,
    h4 b,
    h4 center,
    h4 em,
    h4 i,
    h4 span,
    h4 strong,
    h5,
    h5 a,
    h5 a b,
    h5 abbr,
    h5 b,
    h5 center,
    h5 em,
    h5 i,
    h5 span,
    h5 strong,
    h6,
    h6 a,
    h6 a b,
    h6 abbr,
    h6 b,
    h6 center,
    h6 em,
    h6 i,
    h6 span,
    h6 strong,
    header,
    hgroup,
    html,
    i,
    iframe,
    input,
    ins,
    kbd,
    label,
    legend,
    li,
    listing,
    main,
    mark,
    marquee,
    menu,
    meter,
    multicol,
    nav,
    nobr,
    object,
    ol,
    option,
    output,
    p,
    plaintext,
    pre,
    progress,
    q,
    rb,
    rp,
    rt,
    ruby,
    s,
    samp,
    section:not(.hero-wrapper):not(.card-media),
    small,
    span,
    strike,
    strong,
    sub,
    summary,
    sup,
    svg,
    table,
    tbody,
    td,
    text,
    textarea,
    th,
    thead,
    time,
    tr,
    tt,
    u,
    ul,
    var,
    video,
    xmp {
      background: black !important;
      background-color: black !important;
    }
    a,
    article,
    aside,
    basefont,
    bdi,
    big,
    blink,
    blockquote,
    body,
    button,
    canvas,
    caption,
    center,
    code,
    col,
    colgroup,
    command,
    dd,
    del,
    details,
    dir,
    div,
    dl,
    dt,
    embed,
    fieldset,
    figcaption,
    figure,
    font,
    footer,
    form,
    header,
    hgroup,
    html,
    iframe,
    img,
    input,
    ins,
    kbd,
    label,
    legend,
    li,
    listing,
    main,
    mark,
    marquee,
    menu,
    meter,
    multicol,
    nav,
    nobr,
    object,
    ol,
    option,
    output,
    p,
    plaintext,
    pre,
    progress,
    q,
    rb,
    rp,
    rt,
    ruby,
    s,
    samp,
    section,
    select,
    small,
    span,
    strike,
    sub,
    summary,
    sup,
    svg,
    table,
    tbody,
    td,
    text,
    textarea,
    th,
    thead,
    time,
    tr,
    tt,
    ul,
    var,
    video,
    xmp {
      color: white !important;
    }
    abbr,
    acronym,
    b,
    b span,
    h1 b,
    h1 strong,
    h2 b,
    h2 strong,
    h3 b,
    h3 strong,
    h4 b,
    h4 strong,
    h5 b,
    h5 strong,
    h6 b,
    h6 strong,
    strong,
    strong span {
      color: yellow !important;
    }
    address,
    address span,
    cite,
    cite span,
    dfn,
    dfn span,
    em,
    em span,
    h1 em,
    h1 i,
    h2 em,
    h2 i,
    h3 em,
    h3 i,
    h4 em,
    h4 i,
    h5 em,
    h5 i,
    h6 em,
    h6 i,
    i,
    i span,
    u,
    u span {
      color: #ffff80 !important;
    }
    dt {
      border-top: thin solid grey !important;
    }
    h1,
    h1 a,
    h1 a b,
    h1 abbr,
    h1 center,
    h1 span,
    h2,
    h2 a,
    h2 a b,
    h2 abbr,
    h2 center,
    h2 span,
    h3,
    h3 a,
    h3 a b,
    h3 abbr,
    h3 center,
    h3 span,
    h4,
    h4 a,
    h4 a b,
    h4 abbr,
    h4 center,
    h4 span,
    h5,
    h5 a,
    h5 a b,
    h5 abbr,
    h5 center,
    h5 span,
    h6,
    h6 a,
    h6 a b,
    h6 abbr,
    h6 center,
    h6 span {
      color: #40c090 !important;
    }
    img {
      background: #808080 !important;
      background-color: #808080 !important;
    }
    abbr,
    acronym {
      border-bottom: 1px dotted !important;
    }
    :focus {
      outline: thin dotted !important;
    }
    a.button,
    a.button abbr,
    a.button acronym,
    a.button b,
    a.button basefont,
    a.button big,
    a.button br,
    a.button code,
    a.button div,
    a.button em,
    a.button font,
    a.button h1,
    a.button h2,
    a.button h3,
    a.button h4,
    a.button h5,
    a.button h6,
    a.button i,
    a.button kbd,
    a.button rb,
    a.button rp,
    a.button rt,
    a.button ruby,
    a.button samp,
    a.button small,
    a.button span,
    a.button strong,
    a.button tt,
    a.button u,
    a.button var,
    a:link,
    a:link abbr,
    a:link acronym,
    a:link b,
    a:link basefont,
    a:link big,
    a:link br,
    a:link code,
    a:link div,
    a:link em,
    a:link font,
    a:link h1,
    a:link h2,
    a:link h3,
    a:link h4,
    a:link h5,
    a:link h6,
    a:link i,
    a:link kbd,
    a:link rb,
    a:link rp,
    a:link rt,
    a:link ruby,
    a:link samp,
    a:link small,
    a:link span,
    a:link strong,
    a:link tt,
    a:link u,
    a:link var,
    div#secondaryNav div#documentNavigation ul.navigationTabs li.tabItem {
      color: #0080ff !important;
    }
    button,
    input,
    textarea,
    select,
    table,
    td,
    th,
    tr,
    tt {
      border: 1px solid #ffffff !important;
    }
    button {
      background: #600040 !important;
      background-color: #600040 !important;
    }
    select {
      -webkit-appearance: listbox !important;
      background: #600060 !important;
      background-color: #600060 !important;
    }
    a:visited,
    a:visited abbr,
    a:visited acronym,
    a:visited b,
    a:visited basefont,
    a:visited big,
    a:visited br,
    a:visited code,
    a:visited div,
    a:visited em,
    a:visited font,
    a:visited h1,
    a:visited h2,
    a:visited h3,
    a:visited h4,
    a:visited h5,
    a:visited h6,
    a:visited i,
    a:visited kbd,
    a:visited rb,
    a:visited rp,
    a:visited rt,
    a:visited ruby,
    a:visited samp,
    a:visited small,
    a:visited span,
    a:visited strong,
    a:visited tt,
    a:visited u,
    a:visited var,
    div#secondaryNav div#documentNavigation ul.navigationTabs li.tabItem.active {
      color: #00ffff !important;
    }
    ::selection,
    ::-moz-selection {
      background: #4080c0 !important;
      background-color: #4080c0 !important;
    }
    a.button:hover,
    a.button:hover abbr,
    a.button:hover acronym,
    a.button:hover b,
    a.button:hover basefont,
    a.button:hover big,
    a.button:hover br,
    a.button:hover code,
    a.button:hover div,
    a.button:hover em,
    a.button:hover font,
    a.button:hover h1,
    a.button:hover h2,
    a.button:hover h3,
    a.button:hover h4,
    a.button:hover h5,
    a.button:hover h6,
    a.button:hover i,
    a.button:hover kbd,
    a.button:hover rb,
    a.button:hover rp,
    a.button:hover rt,
    a.button:hover ruby,
    a.button:hover samp,
    a.button:hover small,
    a.button:hover span,
    a.button:hover strong,
    a.button:hover tt,
    a.button:hover u,
    a.button:hover var,
    a:link:hover,
    a:link:hover abbr,
    a:link:hover acronym,
    a:link:hover b,
    a:link:hover basefont,
    a:link:hover big,
    a:link:hover br,
    a:link:hover code,
    a:link:hover div,
    a:link:hover em,
    a:link:hover font,
    a:link:hover h1,
    a:link:hover h2,
    a:link:hover h3,
    a:link:hover h4,
    a:link:hover h5,
    a:link:hover h6,
    a:link:hover i,
    a:link:hover kbd,
    a:link:hover rb,
    a:link:hover rp,
    a:link:hover rt,
    a:link:hover ruby,
    a:link:hover samp,
    a:link:hover small,
    a:link:hover span,
    a:link:hover strong,
    a:link:hover tt,
    a:link:hover u,
    a:link:hover var,
    a:visited:hover,
    a:visited:hover abbr,
    a:visited:hover acronym,
    a:visited:hover b,
    a:visited:hover basefont,
    a:visited:hover big,
    a:visited:hover br,
    a:visited:hover code,
    a:visited:hover div,
    a:visited:hover em,
    a:visited:hover font,
    a:visited:hover h1,
    a:visited:hover h2,
    a:visited:hover h3,
    a:visited:hover h4,
    a:visited:hover h5,
    a:visited:hover h6,
    a:visited:hover i,
    a:visited:hover kbd,
    a:visited:hover rb,
    a:visited:hover rp,
    a:visited:hover rt,
    a:visited:hover ruby,
    a:visited:hover samp,
    a:visited:hover small,
    a:visited:hover span,
    a:visited:hover strong,
    a:visited:hover tt,
    a:visited:hover u,
    a:visited:hover var {
      background: #400000 !important;
      background-color: #400000 !important;
    }
    body > input#site + div#wrapper span.mk,
    body > input#site + div#wrapper span.mk b,
    input[type='reset'] {
      background: #400060 !important;
      background-color: #400060 !important;
    }
    div[role='button'],
    input[type='button'],
    input[type='submit'] {
      background: #600040 !important;
      background-color: #600040 !important;
    }
    input[type='search'] {
      -webkit-appearance: textfield !important;
    }
    html button[disabled],
    html input[disabled],
    html select[disabled],
    html textarea[disabled] {
      background: #404040 !important;
      background-color: #404040 !important;
    }
    .menu li a span.label {
      text-transform: none !important;
    }
    .menu li a span.label,
    div.jwplayer span.jwcontrolbar,
    div.jwplayer span.jwcontrols {
      display: inline !important;
    }
    a:link.new,
    a:link.new i,
    a:link.new b,
    span.Apple-style-span {
      color: #ffff40 !important;
    }
    body.mediawiki img.tex {
      background: white !important;
      background-color: white !important;
      border: white solid 3px !important;
    }
    text > tspan:first-letter,
    text > tspan:first-line {
      background: inherit !important;
      background-color: inherit !important;
      color: inherit !important;
    }
    div.sbtc div.sbsb_a li.sbsb_d div,
    table.gssb_c tr.gssb_i a,
    table.gssb_c tr.gssb_i b,
    table.gssb_c tr.gssb_i span,
    table.gssb_c tr.gssb_i td {
      background: #003050 !important;
      background-color: #003050 !important;
    }
    img[width='18'][height='18'] {
      height: 18px !important;
      width: 18px !important;
    }
    a > span.iconHelp:empty:after {
      content: 'Help' !important;
    }

    main .card-media__inner,
    main section .hero-inner,
    main .hero .hero-inner {
      background-image: unset !important;
      background-color: rgba(0, 0, 0, 0.4) !important;
    }

    div#gmap,
    div#gmap * {
      background: initial !important;
    }
  }
}
